import {
  ChatSessionBuilder,
  ContentBuilderMessage,
  Persona,
  FileFormat,
  ContentBuilderMessageGroup,
} from './generated';
import {
  Message,
  MessagePublishInfo,
  MessageSourceType,
  MessageType,
  TextMessageContentFormat,
} from '../types';
import { useAuthStore } from '../store/auth';
import LazyAvatar from '../assets/sloth-avatar-blue.svg';
import LazySupportAvatar from '../assets/sloth-support-icon.svg';
import LazyTestUserAvatar from '../assets/test-user-profile.svg';
import { useAppStore } from '../store/app';

const hasAppVersion = (message: ContentBuilderMessage): boolean => {
  const appHasLogicTopics = (message.app_logic?.logic_topics || []).length > 0;
  const appHasCode: boolean =
    (message.code?.code?.length ?? 0) > 0 ||
    ((message.files?.length ?? 0) > 0 &&
      (message.files || []).some(
        (file) => file.content && file.content.length > 0 && file.format === FileFormat.PYTHON
      ));

  return appHasLogicTopics || appHasCode;
};

export const hideRoleTagPrefixFromMessage = (message: string): string => {
  const roleTags = ['@eng', '@dev', '@pm'];
  for (const tag of roleTags) {
    if (message.startsWith(tag)) {
      return message.slice(tag.length).trimStart();
    }
  }
  return message;
};

// eslint-disable-next-line max-lines-per-function
export const contentBuilderMessageGroupToMessage = (
  c: ContentBuilderMessageGroup,
  userSessionStateIdWithPublishedVersion: MessagePublishInfo[]
): Message => {
  const isLazyEmployee = useAuthStore.getState().isLazyEmployee;
  const supportUsersIdList = useAuthStore.getState().supportUsersIdList;

  const avatarUrlOrEmpty = () => {
    if (c.role === Persona.BUILDER) {
      const pictureUrl = c.backend_metadata?.agent_info?.picture_url;
      const agentId = c.backend_metadata?.agent_info?.id;

      if (!isLazyEmployee && agentId && supportUsersIdList.includes(agentId)) {
        return LazySupportAvatar as string;
      }

      if (pictureUrl) {
        return pictureUrl;
      }

      return agentId
        ? (LazyTestUserAvatar as string)
        : (useAuthStore.getState().firebaseUser?.photoURL as string);
    } else {
      return LazyAvatar as string;
    }
  };
  const avatarUrl = avatarUrlOrEmpty() || (LazyAvatar as string);

  const sourceNameOrEmpty = () => {
    if (c.role === Persona.BUILDER) {
      const agentInfo = c.backend_metadata?.agent_info;
      const agentName = agentInfo?.name;
      const agentId = agentInfo?.id;

      if (!isLazyEmployee && agentId && supportUsersIdList.includes(agentId)) {
        if (agentName) {
          return `Lazy Support • ${agentName.split(' ')[0]}`;
        }
        return 'Lazy Support';
      } else if (agentName) {
        return agentName;
      } else if (agentId) {
        return 'Your Team Member';
      } else {
        return useAuthStore.getState().firebaseUser?.displayName;
      }
    } else {
      return useAppStore.getState().appName;
    }
  };

  const sourceName = sourceNameOrEmpty() || '';
  const lastAppVersionPublishedOrEmpty =
    c.content.builder_session_state_id &&
    userSessionStateIdWithPublishedVersion.find(
      (messagePublishInfo) =>
        messagePublishInfo.builderSessionStateId === c.content.builder_session_state_id
    )?.lastAppVersionPublished;
  const lastAppVersionPublished = lastAppVersionPublishedOrEmpty || false;
  const appVersionPublishDateOrEmpty =
    c.content.builder_session_state_id &&
    userSessionStateIdWithPublishedVersion.find(
      (messagePublishInfo) =>
        messagePublishInfo.builderSessionStateId === c.content.builder_session_state_id
    )?.appVersionPublishDate;
  const appVersionPublishDate = appVersionPublishDateOrEmpty || undefined;
  return {
    id: undefined,
    builderSessionStateId: c.content.builder_session_state_id,
    sentAt: c.sent_at ? new Date(Date.parse(c.sent_at || '')) : undefined,
    source: {
      type: c.role === Persona.BUILDER ? MessageSourceType.User : MessageSourceType.System,
      name: sourceName,
      avatarUrl,
    },
    content: {
      format: TextMessageContentFormat.Markdown,
      text: hideRoleTagPrefixFromMessage(c.content.text?.text ?? ''),
      type: MessageType.Text,
    },
    hasAppVersion: hasAppVersion(c.content),
    role: c.role,
    lastAppVersionPublished,
    appVersionPublishDate,
    starterPrompts: c.content.starter_prompts || [],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    metadata: c.content.metadata || undefined,
  };
};

export const extractMessagesFromBuilderSession = (
  session: ChatSessionBuilder,
  userSessionStateIdWithPublishedVersion: MessagePublishInfo[]
): Message[] => {
  const builderChatSessionToRender = session.content_builder.filter((c) =>
    new Set([Persona.GREETER, Persona.BUILDER, Persona.DEVELOPER, Persona.PRODUCT_MANAGER]).has(
      c.role
    )
  );

  return (
    builderChatSessionToRender
      .map((c) => {
        if (c.role === Persona.BUILDER && !c.content.text?.text) {
          return {
            ...c,
            content: {
              ...c.content,
              text: { ...c.content.text, text: 'Applied manual changes' },
            },
          };
        }

        return c;
      })
      // eslint-disable-next-line max-lines-per-function
      .map((c) => {
        return contentBuilderMessageGroupToMessage(c, userSessionStateIdWithPublishedVersion);
      })
  );
};
