import { create } from 'zustand';
import LazyAvatar from '../assets/sloth-avatar-blue.svg';
import DefaultAppIcon from '../assets/default-app-icon.svg';
import { File } from '../api/generated/models/File';
import { FileUploadFile } from '../components/VSCodeEditor/models';

export interface AppStoreState {
  appImageURLMap: Map<string, string>;
  isAppPublished: boolean;
  appName: Map<string, string>;
  publishedAppVersionsMap: Map<string, boolean>;
  isTemplate: boolean;
  emptyAppFiles: FileUploadFile[];
  appFiles: File[];
  hasEmptySecrets: boolean;
  setAppName: (appName: string) => void;
  setAppImageUrl: (imageUrl?: string) => void;
}

export const useAppStore = create<AppStoreState>((set, get) => ({
  appImageURLMap: new Map<string, string>([['lazy-app-icon', LazyAvatar as string]]),
  appName: new Map<string, string>([['lazy-app-name', 'Lazy']]),
  isAppPublished: false,
  publishedAppVersionsMap: new Map<string, boolean>(),
  isTemplate: false,
  emptyAppFiles: [],
  appFiles: [],
  hasEmptySecrets: false,

  setAppName: (appName: string) => {
    const appNameMap = get().appName;
    appNameMap.set('lazy-app-name', appName);
    set({ appName: appNameMap });
  },

  setAppImageUrl: (imageUrl?: string) => {
    const appImageURLMap = get().appImageURLMap;
    appImageURLMap.set(`lazy-app-icon`, imageUrl || (DefaultAppIcon as string));
    set({ appImageURLMap });
  },
}));
