import React, { useState, useRef, useEffect } from 'react';
import RunAppIcon from 'remixicon-react/PlayCircleLineIcon';
import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon';
import CheckboxCircleIcon from 'remixicon-react/CheckboxBlankCircleLineIcon';
import RecordIcon from 'remixicon-react/RecordCircleLineIcon';
import QuestionIcon from 'remixicon-react/QuestionLineIcon';
import classNames from 'classnames';
import Button from './Button';
import { UpgradeToProButton } from '../UpgradeToProButton';
import * as dataTestidConstants from '../../constants';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useAuthStore } from '../../store/auth';
import { useChatStore } from '../../store/chat';
import { Tooltip } from 'react-tooltip';

export interface RunAppButtonProps {
  onRunApp: (number) => void;
  isAppRestarting: boolean;
  isNewVersionAvailable?: boolean;
  isFirstRun?: boolean;
  leftAlignDropDown?: boolean;
  currentRam?: number;
}

// eslint-disable-next-line max-lines-per-function
export const RunAppButton = ({
  onRunApp,
  isAppRestarting,
  isFirstRun = true,
  isNewVersionAvailable = false,
  leftAlignDropDown = true,
  currentRam,
}: RunAppButtonProps) => {
  const [selectedMemory, setSelectedMemory] = useState<string>(currentRam?.toString() || '');
  const { userPermissions } = useAuthStore();
  const { isMemorySelectorDropdownOpen } = useChatStore();

  const Separator = () => (
    <span className="px-2">
      <div className="bg-label-tertiary rounded-full h-1 w-1" />
    </span>
  );

  const MemoryOption = (label, memory) => (
    <span className="flex items-center">
      {label} <Separator /> {memory}
      <div className="flex flex-1 items-end justify-end"></div>
    </span>
  );

  const memoryOptions = [
    { label: 'Default', value: '', showTooltip: true },
    {
      label: MemoryOption('Light', '256 MB'),
      value: '256',
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
    {
      label: MemoryOption('Medium', '512 MB'),
      value: '512',
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
    {
      label: MemoryOption('Large', '2.5 GB'),
      value: String(1024 * 2.5),
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
    {
      label: MemoryOption('Mega', '4.5 GB'),
      value: String(1024 * 4.5),
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
  ];

  const renderTooltip = () => (
    <div
      data-tooltip-content="Let Lazy choose an appropriate value"
      data-tooltip-id="default-memory-tooltip"
    >
      <QuestionIcon className="text-label-tertiary" size={20} />
      <Tooltip id="default-memory-tooltip" place="bottom" />
    </div>
  );

  const handleMemoryChange = (value: string) => {
    setSelectedMemory(value);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
      useChatStore.setState({ isMemorySelectorDropdownOpen: false });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex bg-system-success rounded-md">
        <Button
          className={classNames('bg-system-success text-white hover:bg-system-success/80 gap-1', {
            'cursor-not-allowed': isAppRestarting,
          })}
          dataTestid={
            isFirstRun
              ? dataTestidConstants.TEST_APP_RUN_APP_BUTTON
              : dataTestidConstants.TEST_APP_RUN_AGAIN_APP_BUTTON
          }
          onClick={() => onRunApp(Number(selectedMemory))}
          iconProps={{ icon: RunAppIcon, iconSize: 24 }}
          disabled={isAppRestarting}
          loading={isAppRestarting}
        >
          {isFirstRun ? 'Run app' : isNewVersionAvailable ? 'Update & Run' : 'Run again'}
        </Button>
        <button
          data-testid={dataTestidConstants.TEST_APP_RUN_MEMORY_SELECTOR_DROPDOWN_BUTTON}
          className={classNames(
            'bg-system-success text-white border-l-2 border-[#26A347] rounded-r-md',
            { 'px-1': !isMobileDevice() },
            { 'px-2': isMobileDevice() }
          )}
          onClick={() => {
            useChatStore.setState({ isMemorySelectorDropdownOpen: !isMemorySelectorDropdownOpen });
          }}
        >
          <ArrowDownIcon size="16" />
        </button>
      </div>

      {isMemorySelectorDropdownOpen && (
        <div
          ref={dropdownRef}
          className={classNames(
            'absolute mt-2 w-72 bg-white border',
            ' border-gray-300 rounded-md shadow-lg p-4 z-10',
            { 'right-0': leftAlignDropDown },
            { 'left-[-77.5px]': !leftAlignDropDown && isMobileDevice() },
            { 'left-[-81.5px]': !leftAlignDropDown && !isMobileDevice() }
          )}
        >
          <div className="text-sm mb-2 text-label-secondary font-medium">
            How much memory do you need?
          </div>
          <div
            className="flex flex-col gap-1.5"
            data-testid={dataTestidConstants.TEST_APP_RUN_MEMORY_SELECTOR_OPTIONS}
          >
            {memoryOptions.map((option) => (
              <div
                key={option.value}
                className={classNames('flex items-center justify-between', {
                  'h-6': !isMobileDevice(),
                  'h-7': isMobileDevice(),
                })}
              >
                <label
                  className={classNames('flex items-center font-normal', {
                    'cursor-pointer': !option.upgradeRequired,
                    'cursor-not-allowed': option.upgradeRequired,
                  })}
                  onClick={() => !option.upgradeRequired && handleMemoryChange(option.value)}
                >
                  <span className="mr-2">
                    {selectedMemory === option.value ? (
                      <RecordIcon className="text-system-accent" size={20} />
                    ) : (
                      <CheckboxCircleIcon className="text-label-tertiary" size={20} />
                    )}
                  </span>
                  {option.label}
                </label>
                {option.showTooltip && renderTooltip()}
                {option.upgradeRequired && (
                  <UpgradeToProButton
                    customButtonText="Upgrade"
                    hideIcon
                    customStyles="!bg-purple-100 !text-purple-600 !p-1 !px-2 h-auto text-xs"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
