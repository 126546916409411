import React, { useEffect, useRef } from 'react';
import { useChatStore } from '../../store/chat';
import RichPrompt from './RichPrompt';
import { toast } from 'react-toastify';
import { BuilderInteractionMode } from '../../api/generated/models/BuilderInteractionMode';
import { ContentBuilderMessageGroup, Persona } from '../../api/generated';
import { BUILDER_INTERACTION_MODE } from '../../constants';

interface ChatProps {
  shouldFocus: boolean;
  isAppRun?: boolean;
  submitUserPrompt: (string) => void;
  onStopRequest?: () => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
const Chat = ({ shouldFocus, isAppRun, submitUserPrompt, onStopRequest }: ChatProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatLoading = useChatStore((state) => state.userInputLoading);
  const currentUserPrompt = useChatStore((state) => state.currentUserPrompt);
  const userInputBlocked = useChatStore((state) => state.userInputBlocked);
  const availableActions = useChatStore((state) => state.availableActions);
  const statusMessage = useChatStore((state) => state.statusMessage);
  const revertFunction = useChatStore((state) => state.revertFunction);

  const handleInputFocus = (focus: boolean) => {
    useChatStore.setState({ userInputFocused: focus });
    useChatStore.setState({ hideChatMessages: false });
  };

  async function onHandleUserCancel() {
    if (onStopRequest) {
      try {
        await onStopRequest();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error when cancelling request on backend:', error);
        toast.error('Something went wrong when stopping the prompt.');
      }
    }
    useChatStore.getState().handleUserRequestedCancellation();
  }

  const toggleBuilderInteractionModeHandler = () => {
    const newState =
      useChatStore.getState().currentBuilderInteractionMode === BuilderInteractionMode.CHAT
        ? BuilderInteractionMode.BUILD
        : BuilderInteractionMode.CHAT;

    useChatStore.setState({
      currentBuilderInteractionMode: newState,
    });

    sessionStorage.setItem(BUILDER_INTERACTION_MODE, newState);
  };

  useEffect(() => {
    if (textareaRef?.current != null && shouldFocus) {
      textareaRef.current.focus();
    }
  });
  if (useChatStore.getState().isViewingVersion) {
    return null;
  }

  return (
    <RichPrompt
      textareaRef={textareaRef}
      currentPrompt={currentUserPrompt}
      changeCurrentPrompt={(prompt: string) =>
        useChatStore.setState({
          currentUserPrompt: prompt,
          currentUserPromptIsEmpty: prompt.trim() === '',
        })
      }
      submitUserPrompt={submitUserPrompt}
      blocked={userInputBlocked}
      loading={chatLoading}
      handleUserCancel={onHandleUserCancel}
      handleInputFocus={(focus) => handleInputFocus(focus)}
      displayActions={!currentUserPrompt}
      actions={availableActions}
      statusMessage={statusMessage}
      hideLoadingStop={isAppRun || useChatStore.getState().hideLoadingStop}
      revertFunction={revertFunction}
      isAppRun={isAppRun}
      developerReplyCount={
        useChatStore
          .getState()
          .appChatSession?.content_builder.filter(
            (message: ContentBuilderMessageGroup) => message.role === Persona.DEVELOPER
          ).length || 0
      }
      currentBuilderInteractionMode={useChatStore.getState().currentBuilderInteractionMode}
      toggleBuilderInteractionMode={toggleBuilderInteractionModeHandler}
    />
  );
};

export default Chat;
